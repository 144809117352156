import(/* webpackMode: "eager", webpackExports: ["default"] */ "/drone/src/app/flowbite-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutHeader"] */ "/drone/src/components/layout-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/drone/src/node_modules/.pnpm/next@15.1.4_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/drone/src/node_modules/.pnpm/next@15.1.4_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/drone/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/drone/src/node_modules/.pnpm/next@15.1.4_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"openSans\"}");
